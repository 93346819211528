const commonMethods = {
    // AUTHORITYCODE: 权限编码  【0:不可编辑不可删除, 1:可删除, 2:可编辑, 3:可编辑可删除】
    // ZT: 状态 【0-未开始，1-实施中，2-暂停，3-终止】

    // 启动
    showPlayBtn(row) {
        if (Number(row.ZT) === 0 || Number(row.ZT) === 2) {
            return true;
        } else {
            return false;
        }
    },
    // 暂停
    showPauseBtn(row) {
        if (Number(row.ZT) === 1) {
            return true;
        } else {
            return false;
        }
    },
    // 结束
    showEndBtn(row) {
        if (Number(row.ZT) === 1 || Number(row.ZT) === 2) {
            return true;
        } else {
            return false;
        }
    },
    // 查看
    showReadBtn(row) {
        if (Number(row.AUTHORITYCODE) === 0) {
            return true;
        } else {
            return false;
        }
    },
    // 修改
    showEditBtn(row) {
        if (Number(row.AUTHORITYCODE) === 2 || Number(row.AUTHORITYCODE) === 3) {
            return true;
        } else {
            return false;
        }
    },
    // 删除
    showDelBtn(row) {
        if (Number(row.AUTHORITYCODE) === 1 || Number(row.AUTHORITYCODE) === 3) {
            return true;
        } else {
            return false;
        }
    },
};
export { commonMethods };