<template>
    <div class="assess-target-grid">
        <AssessHeader :currentStep="3" :dataSource="assessHeaderData"></AssessHeader>
        <sn-table-group class="table" :tableGroupAttributes="tableGroupAttributes" @handleChange="handleChange" :commonMethods="commonMethods"></sn-table-group>
    </div>
</template>
<script>
import { commonData } from '../sn-table-group-common';
import { selfData } from './model/3_sn-table-group';
import commonMethodMixin from '../commonMethodMixin';
import { mapActions } from 'vuex';
import { commonMethods } from './model/3_table-methods';
import AssessHeader from './components/assess_header'
export default {
    name: 'assess_config_grid',
    mixins: [commonMethodMixin],
    data() {
        return {
            commonData,
            commonMethods,
            selfData,
            infoPage: 'assess_config_form',
            // 筛选项
            keyword: '', // 关键字
            assessHeaderData: {
                title: '评估配置',
                content: '评估配置根据数据评估需求，明确评估方式（评估人或评估模型）、评估周期对评估记录而形成的评估方案。',
            },
            exportCode: '2003',
            exportName: "评估配置",
        };
    },
    components:{
        AssessHeader
    },
    mounted() {
    },
    methods: {
        ...mapActions([
            'GetPgpzInfoList', //获取评估配置列表
            'DeletePgpzInfo', //删除评估配置
            'UpdatePgpzzt', //更新评估配置状态
        ]),
        async getTableData() {
            this.tableGroupAttributes.loading = true;
            let res = await this.GetPgpzInfoList({
                Key: this.keyword,	//String 内存放评估对象名称
                pageIndex: this.tableGroupAttributes.snPage.currentPage,	//Int 页数
                pageSize: this.tableGroupAttributes.snPage.tiaoshu,	//Int 页面数据量
            })
            this.tableGroupAttributes.snTable.table.data = res.RESULT || [];
            this.tableGroupAttributes.snPage.count = Number(res.COUNT) || 0;
            this.tableGroupAttributes.loading = false;
        },
        // 删除数据
        // eslint-disable-next-line no-unused-vars
        async delData(id, done, instance) {
            let res = await this.DeletePgpzInfo({
                Id: id
            });
            this.delOver(res, done);
        },
        delOver(res, done) {
            if (res.ISSUCCESS) {
                done();
                this.$message({
                    message: res.RESULTDESCRIPTION,
                    type: 'success'
                });
            } else {
                this.$message({
                    message: res.RESULTDESCRIPTION,
                    type: 'error'
                });
            }
            this.getTableData();
        },
        // 启动
        columnPlayClick(val){
            this.handleZt(val[0].ID,'1');
        },
        // 暂停
        columnPauseClick(val){
            this.handleZt(val[0].ID,'2');
        },
        // 结束
        columnEndClick(val){
            this.handleZt(val[0].ID,'3');
        },
        // 更新评估配置状态
        async handleZt(id,zt){
            let res = await this.UpdatePgpzzt({
                Pgpzid: id,
                Zt: zt
            })
            if(res.ISSUCCESS){
                this.$message({
                    message: '操作成功',
                    type: 'success'
                });
                // this.tableGroupAttributes.snPage.currentPage = 1;
                this.getTableData();
            }else{
                this.$message({
                    message: '操作失败',
                    type: 'error'
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.assess-target-grid{
    width: 100%;
    height: 100%;
    .table{
        width: 100%;
        height:calc(100% - 150px);
    }
}
</style>