const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "配置名称",
                prop: "PZMC",
            }, {
                label: "评估记录",
                prop: "PGJLMC",
            }, {
                label: "评估方式",
                prop: "PGFSMC",
            }, {
                label: "评估周期",
                prop: "ZQ",
                prop1: "ZQDWMC",
                hyphen: '',
            }, {
                label: "评估人",
                prop: "PGRMC",
            }, {
                label: "评估模型",
                prop: "PGMXMC",
            }],
            // 操作列
            columnsBtn: {
                buttonData: [{
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnPlay',
                    title: '启动',
                    icon: 'fa fa-play-circle one',
                    color: '#20a0ff',
                    showMethod: 'showPlayBtn',
                }, {
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnPause',
                    title: '暂停',
                    icon: 'fa fa-pause-circle-o one',
                    color: '#20a0ff',
                    showMethod: 'showPauseBtn',
                }, {
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnEnd',
                    title: '结束',
                    icon: 'fa fa-lock three',
                    color: '#20a0ff',
                    showMethod: 'showEndBtn',
                }, {
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnRead',
                    title: '查看',
                    icon: 'el-icon-view',
                    color: 'green',
                    showMethod: 'showReadBtn',
                }, {
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnEdit',
                    title: '修改',
                    icon: 'el-icon-edit',
                    color: 'green',
                    showMethod: 'showEditBtn',
                }, {
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnDelete',
                    title: '删除',
                    icon: 'el-icon-delete',
                    color: 'red',
                    showMethod: 'showDelBtn',
                }],
                width: 150,
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {},
    snButton: {
        buttonData: [{
            isShow: true,
            btnType: 'button',
            operateType: 'buttonNew',
            name: '新建',
            round: true,
            backColor: '#28ccd9',
        }, {
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export { selfData };